import React from "react";
import { useLocation, Outlet } from "react-router-dom";
import SSORedirectRoute from "../components/TNLMRedirect";

const user = localStorage.getItem("profile");

interface PrivateRouteProps {
  children?: React.ReactElement;
}

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const location = useLocation();

  let redirect = `${location.pathname}${location.search}`;
  const sessionTimeout = sessionStorage.getItem("sessionTimeout");
  
  if (redirect.indexOf("/logout") === 0 && sessionTimeout !== "true") {
    //if user logout normaly 
    redirect = "/main/dashboard";
    sessionStorage.setItem("redirect", redirect);
  }else if (redirect.indexOf("/logout") === 0 && sessionTimeout === "true") {
    //do nothing
  }else{
    sessionStorage.setItem("redirect", redirect);
    sessionStorage.setItem("sessionTimeout", "false");

  }
  // localStorage.setItem("redirect", redirect);


  if (!user) {
    return <SSORedirectRoute />;
  } else {
    return children ? children : <Outlet />;
  }
};

export default PrivateRoute;
